import React, { useState, useEffect } from 'react'
import Footer from '../Footer'
import FirstMenu from '../FirstMenu'
import SecondMenu from '../SecondMenu_remove'
import MainSitePopup from '../Popup/MainSitePopup'
import GeoContext from '../../../utils/geoContext'
import "../../../static/layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'

export default function Layout({
  children,
  location,
  pageContext: { layout },
}) {
  const params = new URLSearchParams(location.search)
  const showPopup = params.has('nopopup')

  const [countryData, setCountryData] = useState(null)

  let { scrollableLayout, exclude } = false
  if (layout) {
    scrollableLayout = layout.scrollableLayout
    if (layout.type && layout.type.exclude) {
      exclude = true
    }
  }

  useEffect(() => {
    const geoLocation = async () => {
      try {
        const getCurrentCountry = await fetch(
          'https://scripts.mediavine.com/flooring/countryLookup',
        )
        let getCurrentCountrydata
        try {
          getCurrentCountrydata = await getCurrentCountry.json() // to json
          setCountryData({ ...getCurrentCountrydata })
        } catch (e) {
          // to json fails
        }
      } catch (e) {
        // fetch fails
      }
    }
    geoLocation()
  }, [])

  return exclude ? (
    children
  ) : (
    <GeoContext.Provider value={countryData}>
      {/* <div 
        style={{
          backgroundColor: 'var(--bg)',
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}> */}
        <FirstMenu location={location} scrollableLayout={scrollableLayout} />
        <SecondMenu />
        <div
          className="secondMenuWrap"
          // Conditional styling below - for the articles pages only
          style={{
            position: scrollableLayout && 'relative',
            top: scrollableLayout && '0',
          }}
        >
        </div>
        {!showPopup && <MainSitePopup />}
        {children}
        <Footer location={location} />
      {/* </div> */}
    </GeoContext.Provider>
  )
}

Layout.defaultProps = {
  data: {
    datoCmsArticle: {
      disableAds: false,
    },
  },
}
