/* eslint-disable no-unused-vars */
import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, NavLink } from '../../../../common/lib'
import SubscribeToNewsletter from '../SubscribeToNewsletter'
import SocialMediaLinks from './SocialMediaLinks'
import styles from './Footer.module.scss'

const Footer = ({ location }) => {
  const currentYear = new Date().getFullYear()

  const { allDatoCmsPrtFooter } = useStaticQuery(graphql`
    query {
      allDatoCmsPrtFooter {
        nodes {
          footerLinks {
            label
            url
          }
          logo {
            gatsbyImageData
          }
        }
      }
    }
  `)
  // const interviewSectionMailerLiteGroupId = 109118684
  // const tradingMailerLiteGroupId = 107242519
  // const mailerliteGroupId = location?.pathname?.includes('trading')
  //   ? tradingMailerLiteGroupId
  //   : interviewSectionMailerLiteGroupId

  return (
    <footer>
      <div className={styles.footer}>
        <div className="container">
          {/* {<Row>{}</Row>} */}
          <div className={`${styles.footerDiv} row`}>
            {allDatoCmsPrtFooter.nodes[0].footerLinks
              .slice(0, 2)
              .map((item) => {
                return (
                  <Link
                    to={item.url}
                    key={item.url}
                    className={`text-uppercase ${styles.footerLink} col-2`}
                  >
                    {item.label}
                  </Link>
                )
              })}
            <GatsbyImage
              objectFit="contain"
              alt="footer items"
              image={allDatoCmsPrtFooter.nodes[0].logo.gatsbyImageData}
              className={`col-md-2 ${styles.footerImg}`}
            />
            {allDatoCmsPrtFooter.nodes[0].footerLinks
              .slice(2, 4)
              .map((item) => {
                return (
                  <NavLink
                    href={item.url}
                    key={item.url}
                    className={`text-uppercase ${styles.footerLink} col-2`}
                  >
                    {item.label}
                  </NavLink>
                )
              })}
          </div>
        </div>
      </div>
      <p className={`d-flex justify-content-center py-2 ${styles.policy}`}>
        Practice Reasoning Tests 2015 - 2023. &nbsp;
        <NavLink style={{ color: '#ff8000' }} href="/privacy-policy/">
          Privacy Policy
        </NavLink>
      </p>
    </footer>
  )
}

const renderLink = ({ id, label, url }) => (
  <div key={id}>
    <a href={url} className="footerLink text-decoration-none">
      {label}
    </a>
  </div>
)

const renderColumn = ({ node: { id, title, column } }) => (
  <Col lg={2} md={2} key={id} className={`col ${styles.column}`}>
    <div className={styles.columnHeading}>{title}</div>
    {column.map(renderLink)}
  </Col>
)

export default Footer
