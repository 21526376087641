import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GoGraph } from 'react-icons/go'
import { TbTarget } from 'react-icons/tb'
import { BsGraphUp, BsArrowLeftRight, BsFillPieChartFill } from 'react-icons/bs'
import { IoBriefcase, IoPersonSharp } from 'react-icons/io5'
import { FaCubes } from 'react-icons/fa'
import { RiBarChartHorizontalFill } from 'react-icons/ri'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
} from '../../../../common/lib'
import styles from './FirstMenu.module.scss'
import { MegaMenu } from '../../../../common/components'
import HeaderMegaMenu from './HeaderMegaMenu'
import ThemeToggleComponent from '../ThemeToggler/ThemeToggler'

const iconColor = '#777'

const iconMap = {
  GoGraph,
  TbTarget,
  BsGraphUp,
  IoBriefcase,
  BsArrowLeftRight,
  BsFillPieChartFill,
  FaCubes,
  IoPersonSharp,
  RiBarChartHorizontalFill,
}

const FirstMenu = ({ scrollableLayout }) => {
  const [open, updateOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [state, setState] = useState({
    iconSize: '25',
    minWidth: 'inherit',
  })
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const updateWindowWidth = () => {
    setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowWidth);

      return () => {
        window.removeEventListener('resize', updateWindowWidth);
      };
    }
  }, []);

  const handleMenuCollapse = () => {
    setIsMenuOpen(false)
    updateOpen(false)
  }

  const IconFeather = ({ icon }) => {
    const IconComponent = iconMap[icon]
    return IconComponent ? (
      <IconComponent size={state.size} color={iconColor} />
    ) : null
  }

  const { allDatoCmsPrtHeader, megaMenu } = useStaticQuery(graphql`
    query {
      megaMenu: allDatoCmsMegaMenu(filter: { title: { eq: "PRT" } }) {
        nodes {
          title
          treeChildren {
            title
            link
            position
            treeChildren {
              title
              link
              position
              treeChildren {
                title
                link
                position
              }
            }
          }
        }
      }
      allDatoCmsPrtHeader {
        nodes {
          logo {
            gatsbyImageData
          }
          navitems {
            label
            url
          }
          iconMenu {
            text
            icon
            destination
          }
        }
      }
    }
  `)

  return (
    <>
      <Navbar
        id="header"
        color="light"
        light
        expand="md"
        container="xl"
        style={{
          position: scrollableLayout ? 'relative' : 'sticky',
          zIndex: isMenuOpen ? 9999 : 1000,
        }}
        className="d-none d-sm-none d-md-block"
      >
        <NavbarBrand tag={Link} to="/" onClick={() => updateOpen(false)}>
          <GatsbyImage
            image={allDatoCmsPrtHeader?.nodes[0]?.logo.gatsbyImageData}
            alt="logo"
            class={`${styles.navbarLogoImage}`}
          />
        </NavbarBrand>
        <Nav className={styles.nestedNacCollapse} navbar>
          {megaMenu?.nodes?.map((menuItem, i) => (
            <MegaMenu
              menuData={menuItem}
              isMenuOpen={isMenuOpen}
              handleMenuCollapse={handleMenuCollapse}
              key={i}
              setIsMenuOpen={setIsMenuOpen}
            />
          ))}
          {allDatoCmsPrtHeader.nodes[0]?.navitems.map((item) => {
            return (
              <NavLink
                key={item.url}
                className={styles.firstsideNavLinks}
                href={item.url}
              >
                {item.label}
              </NavLink>
            )
          })}
        </Nav>
        {/* <ThemeToggleComponent /> */}
      </Navbar>
      <Navbar
        id="header"
        color="light"
        light
        expand="md"
        container="xl"
        style={{ position: scrollableLayout ? 'relative' : 'sticky' }}
        className="d-block d-sm-block d-md-none"
      >
        <NavbarBrand tag={Link} to="/" onClick={() => updateOpen(false)}>
          <GatsbyImage
            image={allDatoCmsPrtHeader?.nodes[0]?.logo.gatsbyImageData}
            alt="logo"
            class={`${styles.navbarLogoImage}`}
          />
        </NavbarBrand>
        {/* <button
          onClick={() => updateOpen(!open)}
          aria-label="Toggle navigation"
          type="button"
          className={`navbar-toggler d-block d-sm-block d-md-none ${styles.hamburger}`}
        >
          {
            themeColor === "dark" ? (
              <span
                className="navbar-toggler-icon"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'%3E%3C/path%3E%3C/svg%3E")`,
                }}
              />
            ) : (
              <span
                className="navbar-toggler-icon"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'%3E%3C/path%3E%3C/svg%3E")`,
                }}
              />
            )
            
          }
          
        </button> */}
        {/* {
          windowWidth <= 769 ? (
            <div className={`${styles.navButton}`}>
              <ThemeToggleComponent />
              <button
                onClick={() => updateOpen(!open)}
                aria-label="Toggle navigation"
                type="button"
                className={`FirstMenu-module--toggleNav--2Tcqh navbar-toggler ${styles.hamburger}`}
                
              >
                <span
                  className={`navbar-toggler-icon ${styles.navbarTogglerIconSvg}`}
                />
              </button>
            </div>
          ) : ""
        } */}
        <Collapse isOpen={open} navbar className={styles.navCollapse}>
          <Nav
            style={{ marginLeft: 'auto' }}
            navbar
            // className="align-items-start"
            className={styles.nestedNacCollapse}
          >
            {/* {allDatoCmsPrtHeader.nodes[0]?.iconMenu.map((item) => {
              return (
                <NavLink
                  className={styles.sideNavLinks}
                  href={item.destination}
                  key={item.text}
                >
                  <IconFeather icon={item.icon} />
                  &nbsp;
                  {item.text}
                </NavLink>
              )
            })} */}

            {megaMenu?.nodes?.map((menuItem, i) => (
              <HeaderMegaMenu
                menuData={menuItem}
                isMenuOpen={isMenuOpen}
                handleMenuCollapse={handleMenuCollapse}
                key={i}
                setIsMenuOpen={setIsMenuOpen}
              />
            ))}

            {allDatoCmsPrtHeader.nodes[0]?.navitems.map((item) => {
              return (
                <NavItem className={styles.firstNavItem}>
                  <NavLink
                    key={item.url}
                    className={styles.firstsideNavLinks}
                    href={item.url}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              )
            })}

            {allDatoCmsPrtHeader.nodes[0]?.iconMenu.map((item) => {
              return (
                <NavLink
                  className={styles.sideNavLinks}
                  href={item.destination}
                  key={item.text}
                >
                  <IconFeather icon={item.icon} />
                  &nbsp;
                  {item.text}
                </NavLink>
              )
            })}
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default FirstMenu
