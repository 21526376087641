import React, { useEffect, useMemo, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { document } from 'browser-monads'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import { Instagram } from 'react-feather'
import SubscribeToNewsletter from '../SubscribeToNewsletter'
import './mainPopup.css'

const CloseIcon = () => {
  return (
    <svg
      height="20"
      viewBox="0 0 329.26933 329"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
    </svg>
  )
}

export const PopupWrapper = ({ ispopupopen, showPopup }) => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query PopupQuery {
      allDatoCmsPopup {
        nodes {
          mailerliteGroupId
          heading
          regexPathMatch
          subheading
          image {
            gatsbyImageData(
              width: 400
              height: 600
              imgixParams: { fit: "crop" }
            )
          }
        }
      }
    }
  `)

  const content = useMemo(() => {
    const popupNodes = data.allDatoCmsPopup.nodes
    const index = popupNodes.findIndex((item) =>
      location.pathname.match(item.regexPathMatch),
    )
    if (index === -1) {
      return null
    }
    return popupNodes[index]
  }, [data])

  if (!content) {
    return null
  }
  return (
    <MainSitePopup
      content={content}
      showPopup={showPopup}
      ispopupopened={ispopupopen}
    />
  )
}

const MainSitePopup = ({ content, showPopup, ispopupopened }) => {
  const [ispopupopen, setPopup] = useState(showPopup)
  const [hasSubscribed, setHasSubcribed] = useState(false)
  const body = document.querySelector('body')

  const handlePopupBackground = (popupOpened) => {
    // eslint-disable-next-line no-unused-expressions
    popupOpened
      ? (body.style.overflow = 'hidden')
      : (body.style.overflow = 'visible')

    if (popupOpened) {
      document.body.setAttribute('data-toggled', true)
    }
  }

  useEffect(() => {
    handlePopupBackground(ispopupopen)
    return () => handlePopupBackground(false)
  }, [body.style, ispopupopen])

  useEffect(() => {
    function mouseisout(e) {
      if (!e.toElement && !e.relatedTarget) {
        if (!document.body.dataset.toggled) {
          setPopup(true)
        }
      }
    }

    document.addEventListener('mouseout', mouseisout)

    return () => {
      document.removeEventListener('mouseout', mouseisout)
    }
  }, [])

  if (!ispopupopen) {
    return null
  }

  const handlePopupVisiblity = () => {
    // eslint-disable-next-line no-unused-expressions
    showPopup === undefined
      ? setPopup(false)
      : [ispopupopened(false), setPopup(false)]
  }

  return (
    <div
      className="popup-background"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handlePopupVisiblity()
        }
      }}
    >
      <div className="container custom-popup-wrapper popup-open">
        <div className="row">
          <div className="col-md-6 p-0 img-section">
            <div
              onClick={handlePopupVisiblity}
              style={{
                position: 'absolute',
                right: '4px',
                top: '4px',
                cursor: 'pointer',
              }}
              className="d-block d-md-none icon-mobile"
              aria-hidden="true"
            >
              <CloseIcon />
            </div>
            <GatsbyImage
              className="card-img img-fluid img-popup"
              image={content.image.gatsbyImageData}
              alt="main-site-popup"
            />
          </div>
          <div className="col-md-6 d-flex align-items-center img">
            <div className="custom-popup-content px-4">
              <div className="popup-logo">WikiJob</div>
              <div
                onClick={handlePopupVisiblity}
                style={{
                  position: 'absolute',
                  right: '14px',
                  top: '10px',
                  cursor: 'pointer',
                }}
                className="d-none d-md-block"
                aria-hidden="true"
              >
                <CloseIcon />
              </div>
              <h2 className="popup-heading">
                {hasSubscribed ? 'Thanks for subscribing' : content.heading}
              </h2>
              {!hasSubscribed ? (
                <p className="mt-4">{content.subheading}</p>
              ) : (
                <>
                  <p>
                    Look out for our email.
                    <br />
                    Can’t wait? Check us out on Instagram.
                  </p>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/wikijob.co.uk/?hl=en"
                    rel="noopener norefferer noreferrer"
                    className="follow-btn text-decoration-none text-white rounded"
                    type="button"
                  >
                    FOLLOW US <Instagram className="mx-2" />
                  </a>
                </>
              )}
              <SubscribeToNewsletter
                popup
                mailerliteGroupId={content.mailerliteGroupId}
                setHasSubcribed={setHasSubcribed}
              />
              <p>
                Your{' '}
                <Link to="/privacy-and-terms" className="popups-link">
                  privacy
                </Link>{' '}
                is important to us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupWrapper
