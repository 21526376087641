import React from 'react'
import styles from './HeaderMegaModule.module.scss'
import sortMenu from '../../../../common/utils/sortMenu'
import DropdownIcon from '../../../../common/components/Icons/DropdownIcon'

const HeaderMegaMenu = ({
  menuData,
  isMenuOpen,
  handleMenuCollapse,
  setIsMenuOpen,
}) => {
  return (
    <li className={styles.navbarContainer}>
      {sortMenu(menuData?.treeChildren)?.map(
        ({ title: menuTitle, treeChildren, link }, i) => (
          <div
            onMouseEnter={() => {
              if (setIsMenuOpen) {
                setIsMenuOpen(true)
              }
            }}
            onMouseLeave={() => {
              if (setIsMenuOpen) {
                setIsMenuOpen(false)
              }
            }}
            className={styles.navbarLink}
            key={i}
          >
            <div className={styles.navbarLinkContainer}>
              {/* <span className={styles.title}> */}
              <a href={link} className={styles.title}>
                {menuTitle}
              </a>
              {/* </span> */}
              <DropdownIcon />
            </div>
            {isMenuOpen && (
              <div className={styles.outerSheet}>
                <div className={styles.sheet}>
                  <div className={styles.inner}>
                    {sortMenu(treeChildren)?.map(
                      (
                        {
                          title: subMenuTitle,
                          treeChildren: nestedChild,
                          link: subMenuLink,
                        },
                        i,
                      ) => (
                        <div className={styles.col} key={i}>
                          <div>
                            {/* <div className="megahead"> */}
                            {subMenuLink?.length > 0 ? (
                              <a
                                href={subMenuLink}
                                onClick={handleMenuCollapse}
                              >
                                {subMenuTitle}
                              </a>
                            ) : (
                              subMenuTitle
                            )}
                            {/* </div> */}
                          </div>
                          {nestedChild.length !== 0 ? (
                            <ul>
                              {sortMenu(nestedChild)?.map(
                                ({ title: childTitle, link: childLink }, i) =>
                                  childLink ? (
                                    <li key={i}>
                                      <a
                                        href={childLink}
                                        onClick={handleMenuCollapse}
                                      >
                                        {childTitle}
                                      </a>
                                    </li>
                                  ) : null,
                              )}
                            </ul>
                          ) : null}
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ),
      )}
    </li>
  )
}

export default HeaderMegaMenu
