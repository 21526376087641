import React, { useState } from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { window } from 'browser-monads'
import { GoGraph } from 'react-icons/go'
import { TbTarget } from 'react-icons/tb'
import { BsGraphUpArrow, BsArrowLeftRight, BsFillPieChartFill } from 'react-icons/bs'
import { IoBriefcase, IoPersonSharp } from 'react-icons/io5'
import { FaCubes } from 'react-icons/fa'
import { RiBarChartHorizontalFill } from 'react-icons/ri'
import './style.css'
import styles from './SecondMenu.module.scss'

const iconColor = '#777'

const SecondMenu = () => {
  const [state, setState] = useState({
    iconSize: '25',
    minWidth: 'inherit',
  })

  const { allDatoCmsPrtHeader } = useStaticQuery(graphql`
    query {
      allDatoCmsPrtHeader {
        nodes {
          iconMenu {
            text
            icon
            destination
          }
        }
      }
    }
  `)

  const iconFeather = (icon) => {
    switch (icon) {
      case 'GoGraph':
        return (
          <GoGraph
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'TbTarget':
        return (
          <TbTarget
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'BsGraphUp':
        return (
          <BsGraphUpArrow
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'IoBriefcase':
        return (
          <IoBriefcase
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'BsArrowLeftRight':
        return (
          <BsArrowLeftRight
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'BsFillPieChartFill':
        return (
          <BsFillPieChartFill
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'FaCubes':
        return (
          <FaCubes
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'IoPersonSharp':
        return (
          <IoPersonSharp
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'RiBarChartHorizontalFill':
        return (
          <RiBarChartHorizontalFill
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )

      default:
        break
    }
  }

  const resize = () => {
    if (window.innerWidth <= 991) {
      setState({
        iconSize: '20',
        minWidth: '190px',
      })
    } else {
      setState({
        iconSize: '40',
        minWidth: 'inherit',
      })
    }
  }
  return (
    <div className={`d-none d-sm-none d-md-block ${styles.wrap}`}>
      <nav className="container-xl nav nav-pills nav-fill second-menu">
        {allDatoCmsPrtHeader.nodes[0].iconMenu.map((item, index) => (
          <a
            {...{
              style: { minWidth: state.minWidth },
              className: classNames(
                'nav-item nav-link',
                styles.navItem,
              ),
              rel: 'nofollow noopener noreferrer',
              target: '_blank',
              href: `${item.destination}`,
              key: index,
            }}
          >
            {iconFeather(item.icon)}
            &nbsp;
            {item.text}
          </a>
        ))}
      </nav>
    </div>
  )
}

export default SecondMenu
